<template>
  <v-dialog v-model="dialog">
    <template v-slot:activator="{ on, attrs }">
      <v-btn v-if="ajout" color="success" v-bind="attrs" v-on="on"><v-icon left>mdi-plus</v-icon>ajouter une entrée</v-btn>
      <v-btn v-if="!ajout" color="primary" fab x-small dark class="ma-2" v-bind="attrs" v-on="on"><v-icon>mdi-pencil</v-icon></v-btn>
    </template>

    <v-card>
      <v-card-title class="text-h5 grey lighten-2">
        {{ajout ? 'Ajouter' : 'Modifier'}} une entrée dans '{{categorie.caNom}}'
      </v-card-title>
       <v-divider></v-divider>
       <v-card-text class="pa-4">
        <div v-for="(champ, indexChamp) in categorie.champs" :key="indexChamp"  class="mx-3">
          <h2>{{champ.ccaNom}}</h2>
          <div :id="'champ' + indexChamp"></div>
        </div>
       </v-card-text>

       <v-card-actions>
         <v-spacer></v-spacer>
         <v-btn text @click="dialog = false;">annuler</v-btn>
         <v-btn v-if="ajout" color="success" @click="ajouter">ajouter</v-btn>
         <v-btn v-if="!ajout" color="success" @click="modifier">modifier</v-btn>
       </v-card-actions>
    </v-card>
  </v-dialog>

</template>

<script>
import Vue from 'vue'
import Vuex from 'vuex'
import { createEditor } from 'vueditor'
import 'vueditor/dist/style/vueditor.min.css'
import axios from 'axios';


Vue.use(Vuex);

export default {
  props: ["categorie", "ajout", "entree"],

  data() {
    return {
      dialog: false,
      initialise: false,
      saisies: []
    }
  },

  watch: {
    dialog: function (newValue) {
      if (newValue) {
        if (!this.initialise) {
          this.initialise = true;
          setTimeout(function() {
            this.init();
          }.bind(this), 500);
        }
        else {
            this.miseAjour();
        }
      } 
    }
  },

  mounted() {
  },

  methods: {
    init() {
      var idx = 0;
      this.categorie.champs.forEach((champ) => {
        var editeur = createEditor('#champ' + idx, {
          uploadUrl: '',
          id: '',
          classList: []
        });
        //console.log("editeur", editeur);
        champ.editeur = editeur;
        this.saisies.push({editeur: editeur, champ: champ});
          idx++;
      });
      this.miseAjour();
    },

    miseAjour() {
      console.log("miseAjour");
      this.saisies.forEach(function(saisie) {
        if (this.ajout) {
          console.log("raz", saisie.champ.ccaNom);
          saisie.editeur.setContent("");
        }
        else {
          console.log("mise à jour", saisie.champ.ccaNom, this.entree);
          var valeur = this.entree.versionEncours.valeurs.find(v => v.veCcaId == saisie.champ.ccaCcaId);
          if (valeur != undefined) {
            saisie.editeur.setContent(valeur.veValeur);
          }
        }
      }.bind(this))
    },

    ajouter() {
      // Ajout de l'entrée
      var entree = {
        ecaCaId: this.categorie.caCaId
      }
      axios.post("/api/ecaEntreeCategories", entree)
      .then(function(response) {
        // Récupération de l'id
        var ecaEcaId = response.data.ecaEcaId;

        // Ajout version de version
        var version = {
          vecaEcaId: ecaEcaId,
          vecaDate: new Date()
        };
        axios.post("/api/vecaVersionEntreeCategories", version)
        .then(function(response) {
          // Récupération de l'id
          var vecaVecaId = response.data.vecaVecaId;

          // ajout des valeurs de champs
          var valeurs = [];
          this.categorie.champs.forEach(champ => {
            var valeur = {
              veVecaId: vecaVecaId,
              veCcaId: champ.ccaCcaId,
              veValeur: champ.editeur.getContent()
            }
            valeurs.push(valeur);
          });
          axios.post("/api/veValeurEntrees", valeurs)
          .then(function() {
            this.dialog = false;
            this.$emit("entreeAjoutee");
          }.bind(this))
          .catch(error => {
            console.log("ajout valeurs", error);
          })
        }.bind(this))
        .catch(error => {
          console.log("ajout version", error);
        })
      }.bind(this))
      .catch(error => {
        console.log("ajout entrée", error);
      })
    },

    modifier() {

      // Ajout version de version
      var version = {
        vecaEcaId: this.entree.ecaEcaId,
        vecaDate: new Date()
      };
      axios.post("/api/vecaVersionEntreeCategories", version)
      .then(function(response) {
        // Récupération de l'id
        var vecaVecaId = response.data.vecaVecaId;

        // ajout des valeurs de champs
        var valeurs = [];
        this.categorie.champs.forEach(champ => {
          var valeur = {
            veVecaId: vecaVecaId,
            veCcaId: champ.ccaCcaId,
            veValeur: champ.editeur.getContent()
          }
          valeurs.push(valeur);
        });
        axios.post("/api/veValeurEntrees", valeurs)
        .then(function() {
          this.dialog = false;
          this.$emit("entreeModifiee");
        }.bind(this))
        .catch(error => {
          console.log("ajout valeurs", error);
        })
      }.bind(this))
      .catch(error => {
        console.log("ajout version", error);
      })
    },
}

}
</script>

<style>

</style>