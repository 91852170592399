<template>
  <v-container grid-list-xs>
    <h1>Dashboard</h1>

    <!-- Liste des catégories -->
    <v-card v-for="(categorie, index) in categories" :key="index" class="mb-3 categorie">

      <!-- Titre de la catégorie -->
      <v-card-title>
        {{categorie.caNom}}
        <v-spacer></v-spacer>

        <!-- Bouton ajouter une entrée -->
        <saisie-entree-popup :categorie="categorie" @entreeAjoutee="nouvelleEntreeAjoutee" ajout="true"></saisie-entree-popup>

      </v-card-title>

      <!-- Entrées -->
      <table class="listeEntrees">
        
        <!-- Entête des entrées -->
        <thead>
          <th v-for="(champ, indexChamp) in categorie.champs" :key="indexChamp" :style="'width: ' + (champ.ccaTailleColonne != '' ? champ.ccaTailleColonne : 'auto')">{{champ.ccaNom}}</th>
        </thead>

        <!-- Liste des entrées -->
        <tr v-for="(entree, indexEntree) in categorie.entrees" :key="indexEntree">
          
          <!-- Liste des champs (colonnes) de l'entrée -->
          <td v-for="(valeur, indexValeur) in entree.versionEncours.valeurs" :key="indexValeur" v-html="valeur.veValeur">
            
          </td>

          <!-- Bouton de traitement d'une entrée -->
          <td>
            <saisie-entree-popup :categorie="categorie" :entree="entree" @entreeModifiee="rafraichir" ></saisie-entree-popup>
            <v-btn color="error" fab x-small dark class="ma-2">
              <v-icon>mdi-delete</v-icon>
            </v-btn>
            <v-btn color="info" fab x-small dark >
              <v-icon>mdi-table</v-icon>
            </v-btn>
          </td>
        
        </tr>



      </table>
    </v-card>
  </v-container>  
</template>

<script>
import axios from 'axios';
import SaisieEntreePopup from './SaisieEntreePopup.vue';

export default {
  components: { SaisieEntreePopup },

  data() {
    return {
      categories: []
    }
  },

  methods: {
    rafraichir() {
      var api = "/api/caCategories";
      var filter = {"include": ["champs", {"entrees": [{"versions": [{"valeurs": ["champ"]}]}]}]};
      var url = api + "?filter=" + encodeURI(JSON.stringify(filter));
      console.log(url);
      axios.get(url)
      .then(response => {
        console.log(response.data);
        //console.log(JSON.stringify(response.data));
        var data = response.data;
        data.forEach(cat => {
          cat.entrees.forEach(entree => {
            if (entree.versions.length > 0) {
              entree.versionEncours = entree.versions[entree.versions .length -1];
            }
            else {
              entree.versionEncours = {};
            }
          })
        })
        this.categories = data;
      })
      .catch(error => {
        console.log(error);
      })
    },

    miseEnforme(data) {
      var categories = [];
      data.forEach(cat => {
        var categorie = {titre: cat.caNom, description: cat.caDescription};
        categories.push(categorie);

        
      });
    },

    nouvelleEntreeAjoutee() {
      this.rafraichir();
    }
  },

  mounted() {
    this.rafraichir();
  }
}
</script>

<style scoped>

.categorie {

}

.listeEntrees td, .listeEntrees th {
  border: 1px solid #ddd;
  padding: 8px;
}

.listeEntrees tr:nth-child(even){background-color: #f2f2f2;}

.listeEntrees tr:hover {background-color: #ddd;}

.listeEntrees th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #04AA6D;
  color: white;
}

</style>